import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Lever les Voiles</h1>
        <p>Promouvoir l'Art Vivant et l'Authenticité</p>
        <img src="./images/spectacle.jpg" alt="Spectacle vivant" className="header-image"/>
      </header>

      <section className="About">
        <h2>Qui Sommes-Nous ?</h2>
        <p>
          Lever les Voiles est une association dédiée à la promotion, la production et la diffusion de spectacles vivants et de diverses formes d'art. Nous croyons en l'importance de l'expression artistique et culturelle pour enrichir la société.
        </p>
      </section>

      <section className="Mission">
        <h2>Notre Mission</h2>
        <p>
          Nous offrons un espace de collaboration et d'échange entre artistes, créateurs, techniciens et intervenants du secteur culturel. Nos activités visent à :
        </p>
        <ul>
          <li>Soutenir la création artistique</li>
          <li>Explorer les dynamiques sociales et interactions humaines à travers les arts vivants</li>
          <li>Promouvoir l'authenticité et l'ouverture dans les relations sociales</li>
        </ul>
        <img src="./images/atelier.jpg" alt="Artistes en atelier" className="section-image"/>
      </section>

      <section className="Activities">
        <h2>Nos Activités</h2>
        <ul>
          <li><strong>Ateliers et Stages</strong> : Formations pratiques pour artistes de tous niveaux.</li>
          <li><strong>Résidences Artistiques</strong> : Espaces dédiés à la création et à l'innovation artistique.</li>
          <li><strong>Conférences et Débats</strong> : Discussions pour approfondir la compréhension des arts vivants et des interactions sociales.</li>
          <li><strong>Activités Éducatives et Culturelles</strong> : Programmes pour tous les âges visant à promouvoir l'authenticité et l'inclusion sociale.</li>
        </ul>
        <img src="./images/conference.jpg" alt="Conférence" className="section-image"/>
      </section>

      <section className="Join">
        <h2>Rejoignez-Nous</h2>
        <p>
          Nous collaborons avec diverses associations, institutions et organisations partageant nos objectifs, au niveau local, national et international. Nos partenariats visent à promouvoir la diversité culturelle et l'inclusion dans toutes nos activités.
        </p>
        <p>
          Souhaitez-vous contribuer à notre mission ou participer à nos activités ? Contactez-nous pour plus d'informations sur nos programmes et opportunités de collaboration.
        </p>
        <img src="./images/group.jpg" alt="Groupe d'artistes" className="section-image"/>
      </section>


      <footer className="App-footer">
        <h2>Contact</h2>
        <p>
          Pour toute question ou information, veuillez nous contacter à l'adresse suivante :
        </p>
        <p><strong>Email</strong> : contact@leverlesvoiles.org</p>
        <p>
          © 2024 Lever les Voiles. Tous droits réservés.
        </p>
      </footer>
    </div>
  );
}

export default App;
